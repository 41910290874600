<template>
  <div class="LoginComponent d-flex flex-column justify-center align-content-center align-center">
    <!--IMAGEN-->
    <img class="LogoLogin" src="@/assets/img/logo.svg" />
    <!--TITULO-->
    <h2 class="mt-3">Welcome back to SLU</h2>
    <p class="mt-1">
      Enter your information to access<br />
      to your account
    </p>

    <v-form ref="loginForm" v-model="valid" lazy-validation class="LoginForm d-flex flex-column" @submit.prevent="submitForm">
      <!--INPUTS-->
      <div class="InputContent d-flex flex-column mt-3">
        <div class="LabelInputSlu">E-mail</div>
        <v-text-field
          v-model.trim="email"
          flat
          required
          label ="yourSLUemail@outlook.com"
          append-icon="mdi-email-outline"
          placeholder="yourSLUemail@outlook.com"
          :rules="[requiredInput, validEmail]"
        ></v-text-field>
      </div>

      <div class="InputContent d-flex flex-column inputMargin">
        <div class="LabelInputSlu">Password</div>
        <v-text-field
          v-model="password"
          flat
          required
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          label="You SLU Password"
          :rules="[requiredInput]"
        ></v-text-field>
      </div>

      <!-- Recordar contraseña -->
      <div class="RememberPassword d-flex justify-start align-center inputMargin">
        <v-container class="px-0" fluid>
          <v-checkbox 
          v-model="checkbox" color="#003D6D" label="Remember Password"></v-checkbox>
        </v-container>
      </div>

      <!--BOTON-->
      <div class="FinishButtonCont mt-2 mx-auto">
        <v-btn
          class="FinishButton"
          rounded
          large
          depressed
          :color="colorButton"
          :loading="loading"
          @click="loader = 'loading'"
          type="submit"
        >
          Sign in
        </v-btn>
      </div>
    </v-form>

    

    <!--OLVIDE MI CONTRASEÑA-->
    <div class="ForgotPassword d-flex justify-center align-center mt-4 ">
      Forgot your password?
    </div>
  </div>
</template>
<script>
import CryptoJS from "crypto-js";

import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
import { formValidations } from "@/mixins/formValidations";

export default {
  mixins: [formValidations],
  data() {
    return {
      colorButton:'#003D6D',
      showPassword: false,
      email: null,
      password: null,
      submitted: false,
      valid: true,
      checkbox: true,
      loader: null,
      loading: false,
    };
  },
  mounted() {
    this.getCookie();
  },
  computed: {
    ...mapGetters(["user"]),
    ...mapState({ language: (state) => state.ui.language }),
  },
  methods: {
    //ENVIAR DATOS
    ...mapMutations(["setLanguage"]),
    ...mapActions(["login"]),
    getCookie() {
      if (document.cookie.length > 0) {
        const arr = document.cookie.split("; ");
        for (let i = 0; i < arr.length; i++) {
          const arr2 = arr[i].split("=");
          if (arr2[0] == "userName") {
            this.email = arr2[1];
          }
          if (arr2[0] == "userPwd") {
            const SECRET = process.env.VUE_APP_SECRET_KEY;
            const bytes = CryptoJS.AES.decrypt(arr2[1], SECRET);
            const originalText = bytes.toString(CryptoJS.enc.Utf8);
            this.password = originalText;
          }
        }
      }
    },
    submitForm() {
      this.colorButton = '#A8BFD9';
      this.submitted = true;
      if (this.$refs.loginForm.validate()) {
        const l = this.loader;
        this[l] = !this[l];

        this.login({
          email: this.email,
          password: this.password,
          checkbox: this.checkbox,
        }).finally(() => {
          this[l] = false;
          this.loader = null;
          this.colorButton = '#003D6D';
        });
      }
      else{
        this.colorButton = '#003D6D';
      }
    },
  },
};
</script>
<style lang="less">
@import '~@/assets/style/LoginStyle.less';
</style>
